<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">我的课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                clearable
                placeholder="请输入课程名称"
              />
            </div>
            <el-button
              style="margin-left:20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left:20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >创建课程</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="课程分类"
                align="center"
                prop="courseTypeName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="课件数量"
                align="center"
                prop="coursewareNum"
                show-overflow-tooltip
                min-width="100"
              />
                <el-table-column label="课程来源" align="center">
            <template slot-scope="scope">
              <span>
                {{
                $setDictionary("COURSEORGIN", scope.row.courseOrigin)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center">
            <template slot-scope="scope">
              <span>
                {{
                $setDictionary("AUDITSTATE", scope.row.auditState)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="销售状态" align="center">
            <template slot-scope="scope">
              <span>
                {{
                scope.row.courseOrigin == '20'?'--' : $setDictionary("SELLSTATE", scope.row.salesState)
                }}
              </span>
            </template>
          </el-table-column>
            
              <el-table-column
                label="操作"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:7px 10px"
                    @click.stop="() => handleCreate('edit',scope.row.courseId)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    v-if="scope.row.auditState != '30'"
                    @click="handleDel(scope.row.courseId)"
                    >删除
                  </el-button>
                   <el-button
              style="padding:0 5px"
              type="text"
              size="mini"
              v-show="scope.row.courseOrigin!='20'"
              @click="shelf(scope.row.courseId, scope.row.salesState)"
            >{{ scope.row.salesState === "10" ? "上" : "下" }}架</el-button>
                    <el-button
              style="padding:0 5px"
              v-if="scope.row.auditState !== '30'"
              type="text"
              size="mini"
              @click="
                  goDisinfectionRecord(scope.row.courseId, scope.row.auditState)
                "
            >
              {{
              scope.row.auditState === "20" ? "撤销" : "提交"
              }}审核
            </el-button>
             <el-button
                    type="text"
                    size="mini"
                    v-if="scope.row.auditState == '30'"
                    style="padding:7px 10px"
                    @click.stop="() => handlelookStudent(scope.row.courseName)"
                    >查看学员</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      courseName:'',
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.courseName,
      };
      this.doFetch(
        {
          url: "/minicourse/coursePageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    handleCreate(stu, courseId = "") {
      this.$router.push({
        path: "/web/miniApp/createMiniCouerse",
        query: { courseId, stu },
      });
    },
    // 删除
    handleDel(courseId) {
      this.doDel({
        url: "/minicourse/deleteCourse",
        msg: "你确定要删除该课程吗？",
        ps: {
          type: "post",
          data: { courseId: courseId }
        }
      },true,2);
    },
     // 提/撤 审核
    goDisinfectionRecord(courseId, stu) {
      if (stu === "20") {
        this.doDel({
          title: "撤销审核",
          url: "/minicourse/auditRollback",
          data: {
            courseId
          },
          msg: "该课程已经提交审核，是否撤销审核？",
          ps: {
            type: "post",
            data: { courseId: courseId }
          }
        },true,2);
      } else {
        this.doDel({
          title: "提交审核",
          url: "/minicourse/auditCommit",
          data: {
            courseId
          },
          msg: "课程只有审核通过才可以使用，您是否提交课程审核？",
          ps: {
            type: "post",
            data: { courseId: courseId }
          }
        },true,2);
      }
    },
     // 上下架
    shelf(courseId, stu) {
      this.courseId = courseId
      if (stu === "10") {
        this.doDel({
          title: "上架",
          url: "/minicourse/updateSalesStateOn",
          msg: "你确定要将该课程发布到平台吗？",
          ps: {
            type: "post",
            data: { courseId: courseId }
          }
        },true,2);
      } else {
        this.doDel({
          title: "下架",
          url: "/minicourse/updateSalesStateOff",
          msg: "你确定要将该课程从平台下架吗？",
          ps: {
            type: "post",
            data: { courseId: courseId }
          }
        },true,2);
      }
    },
    //查看学员
    handlelookStudent(courseName) {
         this.$router.push({
        path: "/web/miniApp/myResourceStudentList",
        query: { courseName },
      });

    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>
